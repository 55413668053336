<template>
  <div class="works">
      <LeftMenu current="taskrun"></LeftMenu>
      <div class="main">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/taskrun">未上交</el-breadcrumb-item>
            <el-breadcrumb-item>提交作业</el-breadcrumb-item>
          </el-breadcrumb>
          <el-divider class="divider"></el-divider>
          <div class="big">
              <img class="image" src="../../assets/successwors.png" alt="">
              <p class="title">提交成功</p>
              <button class="but" @click="$router.replace('/taskrun')" >返回分贝学院首页</button>
          </div>
      </div>
  </div>
</template>

<script>
import LeftMenu from '../../components/LeftMenu.vue'
export default {
    components: {
        LeftMenu
    }

}
</script>

<style scoped lang="scss">
 @import '../../styles/initialization.scss';
 @import '../../styles/works.scss';
 .divider{
     margin-top:10px;
 }
 .big{
    padding-top:234px;
    box-sizing: border-box;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
     .image{
     display: block;
     width:100px;
     height:100px;
 }
 .title{
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: CENTER;
    color: #666666;
    margin-bottom: 34px;
    margin-top:10px;
 }
 .but{
     font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    // text-align: LEFT;
    color: #ffffff;
    line-height: 14px;
    width: 164px;
    height: 40px;
    background: #ff004d;
    border: none;
 }
 }
 </style>